import { Locales } from "@/i18n";
import { useTranslation } from "react-i18next";

/** 是否中文环境 */
const useLanguageIsZh = () => {
  const { i18n } = useTranslation();

  return [Locales.zhCN, Locales.zhTW, Locales.zh].includes(i18n.language as Locales);
}

export default useLanguageIsZh;
