import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUS from '@/locales/en-US.json';
import zhCN from '@/locales/zh-CN.json';

export enum Locales {
  'zhCN' = 'zh-CN',
  'enUS' = 'en',
  'zh' = 'zh',
  'zhTW' = 'zh-TW',
}

const REQUEST_LANGUAGE_FLAG = {
  ZH: 'zh-hans',
  EN: 'en',
};
// 后端的语言环境标识使用了和国际化不一致的协议标准，所以需要完成一次映射
export const LANGUAGE_FLAG_MAP = {
  [Locales.zhCN]: REQUEST_LANGUAGE_FLAG.ZH,
  [Locales.zh]: REQUEST_LANGUAGE_FLAG.ZH,
  [Locales.zhTW]: REQUEST_LANGUAGE_FLAG.ZH,
  [Locales.enUS]: REQUEST_LANGUAGE_FLAG.EN,
};

/**
 * 用来通过 [标准语言标识] 映射 [接口返回的语言标识]
 * zh-CN -> zh_CN
 */
export const LOCALES_MAP = {
  [Locales.zhCN]: 'zh_CN',
  [Locales.zh]: 'zh_CN',
  [Locales.zhTW]: 'zh_CN',
  [Locales.enUS]: 'en_US',
};

const ZH_CN_STORAGE = localStorage.getItem(Locales.zhCN);
const EN_STORAGE = localStorage.getItem(Locales.enUS);

/**
 * 
 * @param storageData 本地存储获取的 string
 * @param jsonData 项目中 json 文件中的数据
 * @returns 
 */
const getLocale = (storageData: typeof ZH_CN_STORAGE | typeof EN_STORAGE, jsonData: object) => {
  // 本地存储的数据有 且和项目中的数据长度一致，则直接返回本地存储的数据
  return (storageData && Object.keys(JSON.parse(storageData)).length >= Object.keys(jsonData).length) ? JSON.parse(storageData) : jsonData;
}

i18n
  .use(initReactI18next) // 与 React 集成
  .use(LanguageDetector) // 自动检测语言环境
  .use(HttpApi) // 异步加载资源文件
  .init({
    // 指定翻译资源文件，初始化为空的字典
    resources: {
      [Locales.enUS]: {
        translation: getLocale(EN_STORAGE, enUS),
      },
      [Locales.zhCN]: {
        translation: getLocale(ZH_CN_STORAGE, zhCN),
      },
      [Locales.zh]: {
        translation: getLocale(ZH_CN_STORAGE, zhCN),
      },
      [Locales.zhTW]: {
        translation: getLocale(ZH_CN_STORAGE, zhCN),
      },
    },
    // 语言检测相关的配置
    detection: {
      // 检测语言环境的优先级顺序
      order: ['localStorage', 'navigator'],
    },
    // 默认语言环境
    fallbackLng: 'en',
    // 调试模式，在控制台会有相关的信息
    debug: false,
    // 插值配置对象。用于配置如何处理翻译字符串中的变量。
    interpolation: {
      // 指定是否转义插值变量的值。
      escapeValue: false,
    },
  });

export default i18n;
